import './style.css'
import * as THREE from 'three'
import * as dat from 'lil-gui'
import gsap from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
/**
 * Debug
 */
const gui = new dat.GUI()

const parameters = {
    materialColor: '#fc8d98'
}

gui
    .addColor(parameters, 'materialColor')
    .onChange(() =>
    {
        material.color.set(parameters.materialColor)
        particlesMaterial.color.set(parameters.materialColor)
    })

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Objects
 */
const gltfLoader = new GLTFLoader()
//mask
let mask1
gltfLoader.load(
    'mask/kabuto1.glb',
    (gltf) =>
    {
        mask1 = gltf.scene.children[0]
        mask1.position.set(4,-3,0)
        mask1.rotation.y -= 2
        mask1.scale.set(0.025,0.025,0.025)
        scene.add(mask1)
        console.log(mask1)
    }
)
let mask2
gltfLoader.load(
    'mask/casco4.glb',
    (gltf) =>
    {
        mask2 = gltf.scene.children[0]
        mask2.position.set(-4.3,-3,0)
        mask2.rotation.y -= 2
        mask2.scale.set(0.025,0.025,0.025)
        scene.add(mask2)
        console.log(mask2)
    }
)



 //text
 var i = 0;

 var text = "Welcome to the Dojo"
 function typing()
 {
    if(i<text.length){
        document.getElementById("text1").innerHTML += text.charAt(i);
        i++;
        setTimeout(typing,100);
    }
 }
typing();
 var s = 0;
 var text2 = "A Samurai Shift, Coming Soon"
 function typing2()
 {
    if(s<text2.length){
        document.getElementById("text2").innerHTML += text2.charAt(s);
        s++;
        setTimeout(typing2,100);
    }
 }

 //text
let fly = false
 var x = 0;
 var text3 = "now take a look at whats to come"
 function typing3()
 {
    if(x<text3.length){
        document.getElementById("text3").innerHTML += text3.charAt(x);
        x++;
        setTimeout(typing3,100);
    }
    else if(x = text3.length)
    {
        fly = true
    }
 }

// Texture
const textureLoader = new THREE.TextureLoader()
const gradientTexture = textureLoader.load('textures/gradients/3.jpg')
gradientTexture.magFilter = THREE.NearestFilter

// Material
const material = new THREE.MeshToonMaterial({
    color: parameters.materialColor,
    gradientMap: gradientTexture
})

// Objects
const objectsDistance = 4
const mesh1 = new THREE.Mesh(
    new THREE.TorusGeometry(1, 0.4, 16, 60),
    material
)
const mesh3 = new THREE.Mesh(
    new THREE.TorusKnotGeometry(0.8, 0.35, 100, 16),
    material
)

mesh1.position.x = 2
mesh3.position.x = 2

mesh1.position.y = - objectsDistance * 0
mesh3.position.y = - objectsDistance * 2

//cene.add(mesh1,  mesh3)

const sectionMeshes = [ mesh1, mesh3 ]

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 1.5)
directionalLight.position.set(2, 1, 3)
scene.add(directionalLight)

/**
 * Particles
 */
// Geometry
const particlesCount = 10000
const positions = new Float32Array(particlesCount * 3)

for(let i = 0; i < particlesCount; i++)
{
    positions[i * 3 + 0] = (Math.random() - 0.5) * 30
    positions[i * 3 + 1] = objectsDistance * 2 - Math.random() * 3 * 15
    positions[i * 3 + 2] = (Math.random() - 0.5) * 100
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: textureLoader,
    size: 0.03
})

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Scroll
 */
let scrollY = window.scrollY
let currentSection = 0

window.addEventListener('scroll', () =>
{
    scrollY = window.scrollY
    const newSection = Math.round(scrollY / sizes.height)
    if(newSection != currentSection)
    {
        currentSection = newSection

        gsap.to(
            sectionMeshes[currentSection],
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+=6',
                y: '+=3',
                z: '+=1.5'
            }
        )
    }
    if(scrollY > 450 && scrollY < 465){
        typing2();
    }
    if(scrollY > 1600 && scrollY <1625)
{
    typing3();
}
})
/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{


    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Animate camera
    camera.position.y = - scrollY / sizes.height * objectsDistance

    const parallaxX = cursor.x * 0.5
    const parallaxY = - cursor.y * 0.5
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    // Animate meshes
    for(const mesh of sectionMeshes)
    {
        mesh.rotation.x += deltaTime * 0.1
        mesh.rotation.y += deltaTime * 0.12
    }

    console.log(scrollY)
    //animate mask
    if(currentSection == 1)
    {
        if(mask1)
        {
            if(mask1.rotation.y < 0)
            {
              mask1.rotation.y += 0.02
            }
            if(mask1.position.x > -0.32)
            {
              mask1.position.x -= 0.04
            }
        }
        if(mask2)
        {
            if(mask2.rotation.y < 0)
            {
                mask2.rotation.y += 0.02
            }
            if(mask2.position.x < -0.33)
            {
                mask2.position.x += 0.04
            }
        }
    }
    else if(currentSection != 1)
    {
        if(mask1)
        {
            if(mask1.rotation.y > -2)
            {
                mask1.rotation.y -= 0.02
            }
            if(mask1.position.x < 4)
            {
                mask1.position.x += 0.04
            }
        }
        if(mask2)
        {
            if(mask2.rotation.y > -2)
            {
                mask2.rotation.y -= 0.02
            }
            if(mask2.position.x > -4)
            {
                mask2.position.x -= 0.04
            }
        }
    }


if(fly)
{
   camera.position.z -= 0.1
}

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()